.home-slide {
    overflow: hidden;
    position: relative;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
}

.home-slide-container {
    position: relative;
}

.home-slide-inner {
    white-space: nowrap;
    transition: transform 0.5s;
}

.home-slide-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    color: #fff;
    cursor: pointer;
}

.home-slide-item-inner {
    background: #f7f8fe;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: flex;
    position: relative;
}


.home-slide-item-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-slide-item-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 40px;
    width: 70%;
    height: 100%;
    white-space: pre-line;
}

.home-slide-item-text h2 {
    color: #222;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.home-slide-item-text span {
    display: inline-block;
    color: #4A5568;
    margin-bottom: 15px;
}

.home-slide-item-button {
    height: 50px;
    min-height: 40px;
    background: #FF4F4F;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-slide-arrow {
    position: absolute;
    z-index: 2;
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 5px #BBB;
    cursor: pointer;
}

.home-slide-arrow img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}


@media screen and (max-width: 1024px) {
    .home-slide-item-text h2 {
        font-size: 26px;
        margin-bottom: 5px;
    }

    .home-slide-item-text span {
        margin-bottom: 5px;
    }
}


@media screen and (max-width: 768px) {
    .home-slide-item-text h2 {
        font-size: 14px;
        margin-bottom: 2px;
    }

    .home-slide-item-text span {
        margin-bottom: 2px;
        font-size: 12px;
    }

    .home-slide-item-button {
        height: 30px;
        min-height: 30px;
        margin-top: 4px;
        font-size: 12px;
    }

}
.order {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.order-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
}

.order-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.order-back {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
    cursor: pointer;
}

.order-back img {
    width: 12px;
    height: 20px;
    object-fit: contain;
}

.order-title h2 {
    padding-left: 10px;
    font-size: 30px;
    color: #222;
    font-weight: bold;
}

.order-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
}


/* ORDER FORM */
.order-form {
    width: calc(100% - 400px);
}

.order-form-box {
    padding: 20px 0;
    border-top: 0.3px solid rgba(74, 85, 104, 0.3);
}

.order-form h3 {
    font-size: 20px;
    color: #222;
    margin-bottom: 10px;
}

.order-form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.order-form-row p {
    font-size: 14px;
    color: #222;
    width: 30%;
}

.order-form-row input {
    width: 70%;
    height: 50px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    padding-left: 12px;
    outline: none;
    font-weight: 500;
}

.order-form-row textarea {
    width: 70%;
    height: 120px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    font-weight: 500;
}

.space {
    height: 50px;
}


.order-delivery-types {
    background: #F3F2F7;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.order-delivery-type {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}

.order-delivery-type-active {
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 0 5px #e1e1e1;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}


.order-address {
    width: 70%;
}

.order-address-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.order-address-item span {
    font-size: 14px;
    font-weight: normal;
}

.order-address-add {
    margin-top: 10px;
    color: #FF4F4F;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

/* ORDER PRODUCTS */
.order-products {
    width: 350px;
    border: 0.3px solid rgba(74, 85, 104, 0.3);
    padding: 20px;
    border-radius: 15px;
}

.order-products h3 {
    font-size: 20px;
    color: #222;
    margin-bottom: 10px;
}


.order-product-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-product-line {
    border-bottom: 1px solid #eaeaea;
    margin-top: 5px;
    margin-bottom: 5px;
}

.order-product-row p.first {
    width: 60%;
    justify-content: flex-start;
}

.order-product-row p {
    font-size: 15px;
    padding: 5px 0;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.order-product-row b {
    font-size: 15px;
    padding: 10px 0;
}









/* PAYMENT METHODS */
.order-payment-methods {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.payment-method {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.payment-method-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}


.checked {
    border: 4px solid #FF4F4F;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.checked-inner {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #FF4F4F;
}

.unchecked {
    border: 3px solid #777777;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}


.order-box-button {
    background: var(--primary);
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 20px #eaeaea;
    cursor: pointer;
    transition: transform 0.5s;
}

.order-box-button:hover {
    transform: scale(1.05);
}













/* ORDER TAG STYLES */
.order-tag-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0
}

.order-tag {
    font-size: 14px;
    font-weight: bold;
    padding: 8px 15px;
    border-radius: 10px;
}

.order-tag.blue {
    color: white;
    animation: pulse-blue 1s infinite;
    -webkit-animation: pulse-blue 1s infinite;
    background: #2196f3;
}

.order-tag.red {
    color: white;
    animation: pulse-red 1s infinite;
    -webkit-animation: pulse-red 1s infinite;
    background: #f44336;
}

.order-tag.yellow {
    color: white;
    animation: pulse-yellow 1s infinite;
    -webkit-animation: pulse-yellow 1s infinite;
    background: #ffc107;
}

.order-tag.green {
    color: white;
    animation: pulse-green 1s infinite;
    -webkit-animation: pulse-green 1s infinite;
    background: #8bc34a;
}

.order-tag.whiteblue {
    color: white;
    animation: pulse-whiteblue 1s infinite;
    -webkit-animation: pulse-whiteblue 1s infinite;
    background: #4fc3f7;
}


.order-tag.darkgreen {
    color: white;
    animation: pulse-darkgreen 1s infinite;
    -webkit-animation: pulse-darkgreen 1s infinite;
    background: #4caf50;
}

.order-tag.purple {
    color: white;
    animation: pulse-purple 1s infinite;
    -webkit-animation: pulse-purple 1s infinite;
    background: #3f51b5;
}

.order-tag.darkblue {
    color: white;
    animation: pulse-darkblue 1s infinite;
    -webkit-animation: pulse-darkblue 1s infinite;
    background: #0D47A1;
}

.order-tag.pink {
    color: white;
    animation: pulse-pink 1s infinite;
    -webkit-animation: pulse-pink 1s infinite;
    background: #e91e63;
}






/* BLUE ANIMATION */
@-webkit-keyframes pulse-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 #2196f3;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-blue {
    0% {
        -moz-box-shadow: 0 0 0 0 #2196f3;
        box-shadow: 0 0 0 0 #2196f3;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}



/* RED ANIMATION */
@-webkit-keyframes pulse-red {
    0% {
        -webkit-box-shadow: 0 0 0 0 #f44336;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-red {
    0% {
        -moz-box-shadow: 0 0 0 0 #f44336;
        box-shadow: 0 0 0 0 #f44336;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}


/* YELLOW ANIMATION */
@-webkit-keyframes pulse-yellow {
    0% {
        -webkit-box-shadow: 0 0 0 0 #ffc107;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-yellow {
    0% {
        -moz-box-shadow: 0 0 0 0 #ffc107;
        box-shadow: 0 0 0 0 #ffc107;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}


/* GREEN ANIMATION */
@-webkit-keyframes pulse-green {
    0% {
        -webkit-box-shadow: 0 0 0 0 #8bc34a;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-green {
    0% {
        -moz-box-shadow: 0 0 0 0 #8bc34a;
        box-shadow: 0 0 0 0 #8bc34a;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}




/* WHITEBLUE ANIMATION */
@-webkit-keyframes pulse-whiteblue {
    0% {
        -webkit-box-shadow: 0 0 0 0 #4fc3f7;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-whiteblue {
    0% {
        -moz-box-shadow: 0 0 0 0 #4fc3f7;
        box-shadow: 0 0 0 0 #4fc3f7;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}





/* DARKGREEN ANIMATION */
@-webkit-keyframes pulse-darkgreen {
    0% {
        -webkit-box-shadow: 0 0 0 0 #4caf50;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-darkgreen {
    0% {
        -moz-box-shadow: 0 0 0 0 #4caf50;
        box-shadow: 0 0 0 0 #4caf50;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}





/* PURPLE ANIMATION */
@-webkit-keyframes pulse-purple {
    0% {
        -webkit-box-shadow: 0 0 0 0 #3f51b5;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-purple {
    0% {
        -moz-box-shadow: 0 0 0 0 #3f51b5;
        box-shadow: 0 0 0 0 #3f51b5;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}



/* PURPLE ANIMATION */
@-webkit-keyframes pulse-darkblue {
    0% {
        -webkit-box-shadow: 0 0 0 0 #0D47A1;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-darkblue {
    0% {
        -moz-box-shadow: 0 0 0 0 #0D47A1;
        box-shadow: 0 0 0 0 #0D47A1;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}





/* PURPLE ANIMATION */
@-webkit-keyframes pulse-pink {
    0% {
        -webkit-box-shadow: 0 0 0 0 #e91e63;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-pink {
    0% {
        -moz-box-shadow: 0 0 0 0 #e91e63;
        box-shadow: 0 0 0 0 #e91e63;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}



.order-box-mobile-bottom {
    position: fixed;
    z-index: 1;
    bottom: 80px;
    left: 0;
    right: 0;
    height: 60px;
    background: white;
    border-top: 1px solid #F1F1F1;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    display: none;
    justify-content: center;
    align-items: center;
}

.order-box-mobile-button {
    background: var(--primary);
    width: calc(100% - 40px);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 20px #eaeaea;
    cursor: pointer;
    transition: transform 0.5s;
}

.order-box-mobile-button:hover {
    transform: scale(1.05);
}


@media screen and (max-width: 768px) {

    .order-box-mobile-bottom {
        display: flex;
    }

    .order-title {
        display: none;
    }

    .order-box {
        flex-direction: column;
        margin-top: 0;
    }

    .order-form h3 {
        font-size: 16px;
    }

    .order-form {
        width: 100%;
    }

    .order-products {
        width: 100%;
        margin-bottom: 180px;
    }

    .order-form-box {
        border-top: none;
    }

    .order-form-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 5px 0;
    }

    .order-form-row p {
        width: 100%;
        color: #999;
        font-size: 12px;
        padding-bottom: 4px;
    }

    .order-form-row input {
        width: 100%;
        height: 40px;
    }

    .order-form-row textarea {
        width: 100%;
    }

    .space {
        height: 20px;
    }

    .order-box-button {
        display: none;
    }

}

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


/* CATEGORY STYLE */
.home-categories {
    padding: 20px;
    width: 330px;
    background: #F8F8F8;
    border-radius: 5px;
    position: sticky;
    top: 100px;
}

.home-categories h2 {
    font-size: 20px;
}

.home-category-line {
    width: 60px;
    height: 2px;
    border-radius: 1px;
    margin: 5px 0;
    background: var(--primary);
}

.home-category-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
    border-bottom: 1px dashed #c1c1c1;
}

.home-category-item {
    color: #222;
    font-size: 15px;
    transition: color 0.5s;
}

.home-category-item-inner {
    display: flex;
    justify-self: flex-start;
    align-items: flex-start;
    padding: 7px 0;
    cursor: pointer;
}

.home-category-item-inner span {
    font-size: 15px;
    transition: color 0.5s;
}

.home-category-item-inner:hover span {
    color: var(--primary)
}

.home-category-item.active img{
    transform: rotate(90deg);
}

.home-category-item img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 3px;
    transform: rotate(0);
    transition: transform 0.3s;
}

.home-category-item.active .home-category-item-children {
    opacity: 1;
    max-height: 100px;
}

.home-category-item-children {
    padding: 0 5px;
    margin-left: 15px;
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s linear, max-height 0.5s;
    overflow: hidden;
}

.home-category-item-child {
    padding-bottom: 10px;
    padding-top: 2px;
    font-size: 14px;
    display: block;
    color: #222;
    transition: color 0.5s;
}

.home-category-item-child:hover {
    color: var(--primary)
}

/* HOME CONTENT STYLE */
.home-content {
    width: calc(100% - 350px);
    margin-left: 10px;
}




/* SLIDER STYLES */
.slider {
    margin-bottom: 30px;
}


/* MINI SLIDES */
.slides {
    margin-top: 15px;
    margin-right: -2.33333%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.slide-item {
    width: 31%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s;
    margin-right: 2.33333%;
}

.slide-item:hover {
    transform: scale(1.05);
}

.slide-item img {
    width: 100%;
}

.slide-item-box {
    position: absolute;
    left: 0;
    top: 20px;
    height: 70px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 30%;
    padding: 10px;
    border-radius: 0 35px 35px 0;
}

.slide-item-box-sale {
    font-size: 13px;
    color: #FF4F4F;
}

.slide-item-box p {
    font-size: 13px;
    font-weight: bold;
}

.slide-item-button {
    position: absolute;
    left: 10px;
    bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #222;
}





/* HOME TITLE STYLES */
.home-title h2 {
    font-size: 26px;
    font-weight: bold;
}

.home-title img {
    width: 120px;
    height: 22px;
    object-fit: contain;
    margin-top: 2px;
}

.home-title p {
    font-size: 14px;
    color: #4A5568
}


/* TOP PRODUCTS */
.top-products {
    margin-top: 40px;
}

.top-products-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    margin-top: 20px;
}

.top-product-item {
    width: 20%;
    border: 0.5px solid rgba(74, 85, 104, 0.3);
}







@media screen and (max-width: 768px) {

    .home-categories {
        display: none;
    }

    .home-content {
        width: 100%;
        margin-left: 0;
    }

    .slider {
        margin-bottom: 0;
    }

    .slides {
        display: none;
        width: 300%;
        margin-top: 0;
    }

    .slide-item {
        width: 100%;
    }

    .slide-item img {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .top-product-item {
        width: 50%;
    }

}





.about {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-wrapper {
    width: 1240px;
    padding: 0 20px;
}

.about-wrapper h1 {
    font-size: 30px;
    color: #222;
    text-indent: 20px;
    margin-top: 20px;
}

.about-wrapper p {
    font-size: 14px;
    margin-bottom: 12px;
    color: #333;
    text-indent: 20px;
}

.about-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.about-box-text {
    width: 70%;
    padding-right: 30px;
}

.about-box img {
    width: 30%;
    object-fit: contain;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {

    .about-wrapper h1 {
        margin-bottom: 0;
    }

    .about-box {
        flex-direction: column;
        margin-top: 5px;
    }

    .about-box-text {
        width: 100%;
        padding-right: 0;
    }

    .about-box img {
        width: 100%;
    }
}




.privacy {
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-wrapper {
    width: 1240px;
    padding: 20px;
}

.privacy-wrapper h1 {
    font-size: 26px;
    color: #222;
    padding-bottom: 10px;
    text-align: center;
}

.privacy-wrapper p {
    font-size: 14px;
    margin: 12px 0;
    color: #333;
}

.privacy-space {
    height: 20px;
}
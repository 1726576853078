.product-box {
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.product-image {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.product-image img {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s;
}

.product-image img.food {
    opacity: 1;
    position: absolute;
    z-index: -1;
    width: calc(100% - 70px);
    height: calc(100% - 70px);
}

.product-image.product-image-loaded img.food {
    opacity: 0;
}

.product-image.product-image-loaded img {
    opacity: 1;
}

.product-info {
    padding: 10px;
    min-height: 100px;
}

.product-name {
    font-size: 13px;
    font-weight: bold;
    max-height: 50px;
    overflow: hidden;
    color: #222
}

.product-code {
    font-size: 12px;
    color: #666
}

.product-article {
    white-space: initial;
    overflow: hidden;
    font-size: 12px;
    color: #666
}



.product-price-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.product-price {
    font-size: 15px;
    font-weight: bold;
    margin-right: 15px;
    color: var(--primary)
}

.product-old-price {
    text-decoration: line-through;
    color: #4A5568;
    font-size: 13px;
}

.product-remaining {
    background: #f44336;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    color: white;
    padding: 3px 7px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
}

.product-remaining.green {
    background: #8bc34a;
}

.product-card-button {
    background: #F8F8F8;
    margin: 10px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: #4A5568;
    position: relative;
}

.product-card-plus-button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    position: absolute;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card-plus-button img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}


.product-card-minus-button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    position: absolute;
    left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card-minus-button img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.notification {
    color: white;
    font-size: 14px;
    padding-top: 5px;
}


.product-heart {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    border-radius: 18px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product-heart-active {
    background: var(--primary);
    animation: heartbeat 0.5s 1;
}

.product-heart>img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}



@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(.75);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(0.75);
    }

    100% {
        transform: scale(1);
    }
}








/* PRODUCT VIEW STYLES */
.product-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-view-wrapper {
    width: 1240px;
    padding: 0 20px;
    position: relative;
}


.product-view-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.product-view-back {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
    cursor: pointer;
}

.product-view-back img {
    width: 12px;
    height: 20px;
    object-fit: contain;
}

.product-view-title h2 {
    font-weight: bold;
    padding-left: 10px;
    font-size: 18px;
    color: #222;
}


.product-view-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    position: relative;
}

.product-view-social-networks {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.product-view-social-copy {
    padding: 0 10px;
    cursor: pointer;
}

.product-view-social-item {
    margin-right: 10px;
}

.product-view-social-networks img {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

.product-view-image {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    border: 0.5px solid #f4f4f4;
    border-radius: 10px;
    position: relative;
    min-height: 200px;
}

.product-view-image>img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    cursor: zoom-in;
    transition: opacity 0.5s;
}

.product-view-image>img.default {
    width: 100px;
    height: 100px;
    position: absolute;
}

.product-view-gallery {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.product-view-gallery-item {
    width: 30%;
    margin-right: 3.33333%;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    padding: 10px;
    margin-bottom: 10px;
}

.product-view-gallery-item.active {
    border: 1px solid var(--primary);
}

.product-view-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.product-view-information {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-view-information h1 {
    font-size: 30px;
    color: #222;
}

.product-view-available {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-self: baseline;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: bold;
}

.product-view-available.green {
    background: #8bc34a;
}

.product-view-available.red {
    background: #f44336;
}


.product-view-information p {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
}

.product-view-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.product-view-price b {
    font-weight: bold;
    font-size: 14px;
}

.product-view-price span {
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
}

.product-view-price span.old-price {
    color: #FF4F4F;
    text-decoration: line-through;
    font-size: 15px;
}


.product-view-card-button {
    background: var(--primary);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 20px #eaeaea;
    cursor: pointer;
    transition: transform 0.5s;
    margin-top: 20px;
    width: 300px;
}

.product-view-card-button:hover {
    transform: scale(1.05);
}


.product-view-card-counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin-top: 20px;
}

.product-view-amount {
    font-weight: bold;
    font-size: 18px;
    width: 170px;
    text-align: center;
    height: 50px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    outline: none;
}

.product-view-card-minus-button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product-view-card-minus-button img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.product-view-card-plus-button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product-view-card-plus-button img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}





@media screen and (max-width: 768px) {

    .product-view-title {
        display: none;
    }

    .product-view-box {
        margin-top: 100px;
        flex-direction: column;
        padding-bottom: 120px;
    }

    .product-view-image {
        width: 100%;
    }

    .product-view-information {
        margin-top: 20px;
        width: 100%;
    }

    .product-view-information h1 {
        font-size: 22px;
    }

    .product-view-card-counter-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product-view-card-counter {
        width: 100%;
    }

    .product-view-card-button {
        width: 100%;
    }

    .product-view-social-networks {
        padding: 25px 5px;
    }

}









.zoom-modal {
    outline: none;
    margin: 20px;
    width: 100%;
    max-width: 800px;
    min-height: 400px;
    height: 80%;
    background: white;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.zoom-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 46px;
    height: 46px;
    border-radius: 23px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.zoom-modal .zoom-close img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    cursor: pointer;
}

.zoom-modal img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: zoom-out;
}
.footer {
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.footer-wrapper {
    max-width: 1240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}




/* FOOTER BOX STYLES */
.footer-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px dashed #C1C1C1;
}

.footer-item.footer-main {
    width: 32%;
    padding-right: 20px;
}

.footer-item {
    padding: 20px 20px 20px 0;
    width: 22%;
    display: flex;
    flex-direction: column;
}

.footer-item span {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.footer-item a {
    margin-bottom: 10px;
    font-size: 14px;
    color: #4A5568;
    transition: color 0.5s;
}

.footer-item a:hover {
    color: var(--primary)
}

.footer-logo {
    margin-left: -15px;
    margin-bottom: 15px;
}

.footer-logo img {
    width: 200;
    height: 50px;
    object-fit: contain;
}

.footer-item p {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}

.footer-info {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

.footer-info img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 5px;
}

.footer-info b {
    font-weight: 500;
    font-size: 13px;
    color: #666;
}

.footer-item-contact {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #C1C1C1;
    padding: 10px 0;
}

.footer-item-contact img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.footer-item-contact-name {
    padding-left: 13px;
}

.footer-item-contact-text {
    font-size: 12px;
}

.footer-item-contact-value {
    font-weight: bold;
    font-size: 15px;
}





/* COPY PART */
.copy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.copy p {
    font-size: 14px;
    color: #999;
}

.copy img {
    height: 25px;
    margin-top: 10px;
}

.social-network {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-network-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-network-box a {
    padding-left: 5px;
    margin-top: -3px;
    cursor: pointer;
}

.social-network-box img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 8px;
}





@media screen and (max-width: 1050px) {

    .footer {
        padding-bottom: 80px;
    }

    .footer-advantages-item {
        width: 50%;
    }

    .footer-item,
    .footer-item.footer-main {
        width: 50%;
    }

    .copy {
        flex-direction: column;
    }
}


@media screen and (max-width: 550px) {
    .footer-advantages-item {
        width: 100%;
    }

    .footer-item,
    .footer-item.footer-main {
        width: 100%;
    }

}
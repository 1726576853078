.category {
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-wrapper {
    width: 1240px;
    padding: 0 20px;
}


.category-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.category-back {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
    cursor: pointer;
}

.category-back img {
    width: 12px;
    height: 20px;
    object-fit: contain;
}

.category-title h2 {
    padding-left: 10px;
    font-size: 20px;
    color: #222;
    font-weight: bold;
}



.category-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.category-products-container {
    width: calc(100% - 350px);
}

.category-products-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.category-products-children a {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F8F8;
    border-radius: 5px;
    transition: background-color 0.5s;
    padding: 10px 15px;
}


.category-products-children a img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 6px;
    transition: filter 0.5s;
}

.category-products-children span {
    font-size: 14px;
    font-weight: 500;
    color: #222;
    text-align: center;
    transition: color 0.5s;
}
.category-products-children a:hover {
    background: var(--primary);
}

.category-products-children a:hover img {
    filter: invert(100%)
}

.category-products-children a:hover span {
    color: white;
}


.category-products {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.category-product {
    width: 25%;
    border: 0.5px solid rgba(74, 85, 104, 0.3);
}

.category-products-empty {
    width: 100%;
    padding: 150px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #999
}







.search {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-wrapper {
    width: 1240px;
    padding: 0 20px;
}


.search-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.search-back {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
    cursor: pointer;
}

.search-back img {
    width: 12px;
    height: 20px;
    object-fit: contain;
}

.search-title h2 {
    padding-left: 10px;
    font-size: 20px;
    color: #222;
    font-weight: bold;
}

.search-products-empty {
    width: 100%;
    padding: 150px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #999
}


.search-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.search-product {
    width: 16.6%;
    border: 0.5px solid rgba(74, 85, 104, 0.3);
}


.add-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.add-more {
    border: 1px solid var(--primary);
    text-align: center;
    margin-top: 20px;
    align-self: center;
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border-radius: 3px;
    transition: color 0.3s, background-color 0.5s;
}

.add-more:hover {
    background: var(--primary);
    color: white
}






@media screen and (max-width: 768px) {

    .category-products-container {
        width: 100%;
        padding-bottom: 200px;
    }

    .category-title {
        display: none;
    }

    .category-products {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 120px;
    }

    .category-product {
        width: 33.33334%;
    }

    .search-title {
        display: block;
        padding: 0 20px;
    }

    .search-back {
        display: none;
    }

    .search-title h2 {
        padding-left: 0;
        font-size: 18px;
        text-align: center;
    }

    .search-product {
        width: 33.33334%;
    }

    .search {
        padding-bottom: 120px;
    }
}


@media screen and (max-width: 520px) {
    .category-product {
        width: 50%;
    }
    .search-product {
        width: 50%;
    }
}
.mobile-menu {
    user-select: none;
    outline: none;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-bottom: 10px;
    border-top: 1px solid #F1F1F1;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
}


/* Mobile Menu User */
.mobile-menu-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    max-width: 150px;
    padding: 0 10px;
    overflow: hidden;
}

.mobile-menu-user img {
    width: 28px;
    height: 28px;
    object-fit: contain;
}

.mobile-menu-user p {
    color: #828282;
    font-size: 13px;
    margin-top: 5px;
}

/* Mobile Menu Item */
.mobile-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    max-width: 150px;
    padding: 0 10px;
    overflow: hidden;
    height: 50px;
}

.menu-active-menu-image-box {
    width: 28px;
    height: 28px;
}

.mobile-menu-item img {
    width: 28px;
    height: 28px;
    object-fit: contain;
}

.mobile-menu-item img.ava {
    object-fit: cover;
    border: 0.5px solid #eaeaea;
    background-color: #FAFAFA;
    border-radius: 15px;
}


.mobile-menu-item img.menu-active-menu-image {
    display: none;
}

.mobile-menu-item.active img {
    display: none;
}

.mobile-menu-item.active img.menu-active-menu-image {
    display: block;
}

.mobile-menu-item img.ava {
    display: block;
}



.mobile-menu-item p {
    color: #828282;
    font-size: 13px;
    margin-top: 5px;
    text-align: center;
}

.mobile-menu-item.active p {
    color: #222;
    font-weight: bold;
}


@media screen and (min-width: 769px) {
    .mobile-menu {
        display: none;
    }
}




.account {
    overflow: auto;
}

.account-header {
    height: 50px;
    border-bottom: 1px solid #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    position: fixed;
    background: white;
    z-index: 3;
}

.account-header h2 {
    font-size: 16px;
    width: calc(100% - 100px);
    text-align: center;
    height: 20px;
    overflow: hidden;
}

.account-header-settings {
    height: 50px;
    width: 50px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
}

.account-header-settings img {
    width: 28px;
    height: 28px;
    object-fit: contain;
}

.account-header-back {
    height: 50px;
    width: 50px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10px;
}

.account-header-back img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}


.account-header-close {
    position: fixed;
    z-index: 2;
    top: 15px;
    left: 10px;
    width: 46px;
    height: 46px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    padding-top: 3px;
}

.account-header-close img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.account-data {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.account-data img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.account-data img.ava {
    object-fit: cover;
    border: 0.5px solid #eaeaea;
    background-color: #FAFAFA;
    border-radius: 50px;
}

.account-data p {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

.account-data span {
    font-size: 14px;
    margin-top: 5px;
    color: #999
}


.account-menu {
    padding: 20px;
}

.account-menu-item {
    background: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.account-menu-item-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-menu-item-left img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.account-menu-item-left p {
    margin-left: 10px;
    font-size: 15px;
    color: #222
}

.account-menu-item-right {
    width: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-menu-item-right img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}



.account-ad-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4px 20px 4px;
}

.account-ad-box-text b {
    font-size: 14px;
    font-weight: bold;
    color: #222
}

.account-ad-box-text p {
    font-size: 13px;
    color: #999
}

.account-logout {
    color: #FF4F4F;
    text-decoration: underline;
    padding: 10px 0;
    margin-bottom: 100px;
}

.account-ad-box span {
    font-size: 24px;
}




.mobile-account-container {
    padding-top: 50px;
}



.contact {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-wrapper {
    width: 1280px;
    padding: 0 20px;
}

.contact-wrapper h2{
    font-size: 30px;
    padding-left: 20px;
}

.mobile-contact {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.mobile-contact-item {
    width: 300px;
    margin: 20px;
    padding: 20px;
    border: 1px solid #EFEFEF;
    border-radius: 20px;
}

.mobile-contact-line {
    border-bottom: 1px dashed #d1d1d1;
    margin-top: 10px;
}

.mobile-contact-item h3 {
    font-size: 18px;
    color: #222;
}

.mobile-contact-item p {
    margin-top: 10px;
    font-size: 12px;
    color: #999
}

.mobile-contact-item b {
    font-size: 14px;
}




.mobile-menu-item-cart {
    position: absolute;
    z-index: 2;
    background-color: #FF7272;
    font-weight: bold;
    font-size: 12px;
    color: white;
    height: 16px;
    min-width: 16px;
    padding: 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: -25px;
    margin-top: -5px;
}






.mobile-category-search {
    position: relative;
    margin: 15px 20px;
}

.mobile-category-search input {
    border: 1px solid #ECECEC;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    padding-left: 16px;
    padding-right: 60px;
    outline: none;
}

.mobile-category-search-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 0 5px 5px 0;
    background: var(--secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-category-search-button img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.mobile-category-box {
    padding: 0 22px 120px 22px;
}

.mobile-category-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
    border-bottom: 1px solid #e7e7e7;
}

.mobile-category-item img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 10px;
}

.mobile-category-item p {
    color: #222;
    font-size: 14px;
    font-weight: 500;
}